// "use client";
//
// import { ApolloLink, createHttpLink, HttpLink, useMutation } from "@apollo/client";
// import {
//   ApolloNextAppProvider,
//   ApolloClient,
//   InMemoryCache,
//   SSRMultipartLink,
// } from "@apollo/experimental-nextjs-app-support";
// import { setContext } from "@apollo/client/link/context";
// import { PmStore } from "@/state/store";
//
// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = PmStore.getState().token;
//
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });
//
// // Check if we are on the client-side before using 'location'
// let apiUrl = "/api/graphql/";
// if (typeof window !== "undefined" && (location.hostname === "localhost" || location.hostname === "127.0.0.1")) {
//   apiUrl = "https://rentalflow.dwai.xyz" + apiUrl;
// }
//
// const httpLink = createHttpLink({
//   uri: apiUrl,
// });
//
// export const client = new ApolloClient({
//   link: authLink.concat(httpLink), // Replace with your GraphQL endpoint
//   cache: new InMemoryCache(),
// });


"use client";

import {ApolloLink, createHttpLink, HttpLink, useMutation, from} from "@apollo/client";
import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import {setContext} from "@apollo/client/link/context";
import {PmStore} from "@/state/store";
import { eventBus} from "@/state";
import {onError} from "@apollo/client/link/error";
import {} from "@apollo/client/link/core";


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = PmStore.getState().token;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});


const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = PmStore.getState().token;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }));

  return forward(operation);
})


// const activityMiddleware = new ApolloLink((operation, forward) => {
//     // add the recent-activity custom header to the headers
//
//     console.log(operation);
//
//     return forward(operation);
// })

const activityMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {

    if(response?.errors)
    {
      if(response?.errors?.map( (row) => {
        if(row.message.includes("not authorized"))
        {
          // eventBus.next({type: AuthorizationCheckRequired})
        }
      }));
    }



    return response;
  });
});


const resetToken = onError(({ response, networkError }, cb) => {
  if (networkError && networkError.statusCode === 401) {
    // remove cached token on 401 from the server
    //eventBus.next({type: AuthorizationCheckRequired})
    // pass something back to apollo
    cb({ data: response.data });
  }



});



const httpLink = createHttpLink({
  uri: "/api/graphql",
});

export const client = new ApolloClient({
  // link:  authFlowLink.concat(httpLink), // Replace with your GraphQL endpoint
  link: from([
    authMiddleware,
    activityMiddleware,
    httpLink,
    resetToken
  ]),
  cache: new InMemoryCache(),
});