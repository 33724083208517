import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query currentOrganization {
      currentOrganization {
       id
       name
      }
    }
`;

export function apiGetCurrentOrganization() {
    return new Promise(async (resolve, reject) => {

        client
            .query({
                query: query,
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.currentOrganization);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get connected rental flow organization");
                reject(err); // R
            })
    });
}
